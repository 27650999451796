import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Carousel} from "antd";
import logo from "../../assets/images/sp/sp-logo-15.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import delco1 from "../../assets/images/sp/15/delco-1.jpg";
import delco2 from "../../assets/images/sp/15/delco-2.jpg";
import delco3 from "../../assets/images/sp/15/delco-3.jpg";
import delco4 from "../../assets/images/sp/15/delco-4.jpg";
import delco5 from "../../assets/images/sp/15/delco-5.jpg";
import delco6 from "../../assets/images/sp/15/delco-6.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Delcò Mobili | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 15</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://www.delcomobili.ch" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 14, order: 1}} className="text-justify mb-4">
                                <h6 className="text-primary text-uppercase fw-bold">Una storia iniziata nel 1890</h6>
                                <h1>Delcò Mobili</h1>
                                <p>Dal 1990 nella sede di S. Antonino è una realtà ticinese dalla grande tradizione ed esperienza nel ramo della vendita, della consulenza e della progettazione dei vostri spazi abitativi.</p>
                                <p>Questa realtà radicata sul territorio è in continua evoluzione. Attenta alle ultime tendenze stilistiche, al design più ricercato, alla cura dei dettagli e dei materiali, ma soprattutto attenta alle vostre esigenze. Lo staff Delcò è in grado di consigliare e seguire la propria clientela dalla progettazione per passare alla ristrutturazione e concludere con la posa dell’ultimo complemento.</p>
                                <p>Proprio così, perché da Delco’ è possibile muoversi nei vari reparti: da quello delle cucine a quelli del giorno e della notte così come quello del bagno, dove gli ambienti, sempre aggiornati e all'avanguardia, assumeranno le giuste proporzioni in un connubio di composizioni, forme e colori esclusivi e tecnologici grazie ai prodotti delle migliori marche internazionali dell’ arredamento.</p>
                                <p>I nostri architetti ed esperti di arredamento vi illustreranno programmi completi di ristrutturazione e le migliori soluzioni con progetti personalizzati per valorizzare i vostri ambienti. In tutta l’esposizione di 5.000 mq sarà possibile vedere ambientatie soluzioni con luci e lampade straordinarie che renderanno magici i vostri arredi.</p>
                                <p>Non dimentichiamo i complementi d’arredo selezionati appositamente e molto importanti per dare un valore aggiunto ai vostri spazi. Vasi, composizioni personalizzate e a tema stagionale, candele, sculture e istallazioni di artisti daranno un tocco glamur alle vostre scelte. Come per i tappeti; moderni, orientali o dai colori e forme create a misura per incorniciare il vostro spazio definendolo e arricchendolo con stile.</p>
                                <p>Delcò rinnova i propri valori di sempre: abitare bene significa vivere bene e quindi operiamo con grande attenzione una selezione di prodotti dal gusto italiano ed internazionale offerti con listini in €uro e con garanzia svizzera.</p>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 10, order: 2}} className="text-center">
                                <Carousel autoplay={3500} effect="fade" pauseOnHover={false}>
                                    <div>
                                        <img src={delco1} alt="delco"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={delco2} alt="delco"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={delco3} alt="delco"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={delco4} alt="delco"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={delco5} alt="delco"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={delco6} alt="delco"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
